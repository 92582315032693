import { TabConfig, TabName } from './types'

export const TABS = {
  all: {
    titleKey: 'homepage.tabs.all.title',
    name: 'all',
    isStickyPromoBoxEnabled: true,
    isSellerPromotionEnabled: true,
    feed: {
      titleKey: 'homepage.feed.title',
      areInsertsEnabled: true,
    },
  },
  designer: {
    titleKey: 'homepage.tabs.designer.title',
    name: 'designer',
    isStickyPromoBoxEnabled: false,
    isSellerPromotionEnabled: false,
    feed: {
      titleKey: 'homepage.tabs.designer.feed_title',
      areInsertsEnabled: false,
    },
  },
  electronics: {
    titleKey: 'homepage.tabs.electronics.title',
    name: 'electronics',
    isStickyPromoBoxEnabled: false,
    isSellerPromotionEnabled: false,
    feed: {
      titleKey: 'homepage.tabs.electronics.feed_title',
      areInsertsEnabled: false,
    },
  },
} satisfies Record<TabName, TabConfig>

export const TAB_NAMES = Object.keys(TABS) as Array<keyof typeof TABS>
