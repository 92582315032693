import { useEffect, useRef } from 'react'

import { getVasEntryPoints } from 'data/api'
import { transformVasEntryPointsResponse } from 'data/api/transformers/response'

import { VasEntryPoint } from 'constants/vas-entry-point'
import { AbTestVariant } from 'constants/abtest'

import useHomeInsertsVisibility from 'pages/Home/hooks/useHomeInsertsVisibility'

import useFetch from './useFetch'
import useAbTest from './useAbTest'

const useFetchVasEntryPoints = (entryPointNames: Array<VasEntryPoint>) => {
  const {
    fetch: fetchVasEntryPoints,
    transformedData: data,
    isLoading,
    error,
  } = useFetch(getVasEntryPoints, transformVasEntryPointsResponse)
  const { areInsertsEnabled } = useHomeInsertsVisibility()
  const isVasHarmEnabled = useAbTest({ abTestName: 'vas_harm_v2' })?.variant === AbTestVariant.On
  const filteredEntryPointNamesRef = useRef<Array<VasEntryPoint>>(
    isVasHarmEnabled
      ? entryPointNames.filter(entryPoint => entryPoint !== VasEntryPoint.PromotedClosets)
      : entryPointNames,
  )

  useEffect(() => {
    if (!filteredEntryPointNamesRef.current.length) return
    if (!areInsertsEnabled) return

    fetchVasEntryPoints({ bannerNames: filteredEntryPointNamesRef.current })
  }, [fetchVasEntryPoints, areInsertsEnabled, filteredEntryPointNamesRef])

  return { data, isLoading, error }
}

export default useFetchVasEntryPoints
