import { includes } from 'lodash'

import { TabName } from '../types'
import { TAB_NAMES } from '../constants'

export const getTabName = (tab: string | Array<string> | undefined): TabName =>
  includes(TAB_NAMES, tab) ? (tab as TabName) : 'all'

export const scrollToTab = (tab: TabName, container: HTMLDivElement | null) => {
  if (!container) return

  const targetTab = container.querySelector(`#${tab}`) as HTMLElement
  if (!targetTab) return

  const isScrollable = container.scrollWidth > container.clientWidth
  if (!isScrollable) return

  const containerWidth = container.getBoundingClientRect().width
  const tabWidth = targetTab.getBoundingClientRect().width
  const offset = (containerWidth - tabWidth) / 2

  container.scrollTo({ left: targetTab.offsetLeft - offset })
}
